import React from "react"
import classNames from "classnames"

import * as styles from "./DoubleBorderBox.module.sass"

const DoubleBorderBox = props => {
  // props
  const { children, movedUp = false, treeOnTop = false, alignLeft = false } = props

  const doubleBorderBoxClasses = classNames({
    [styles.doubleBorderBox]: true,
    [styles.movedUp]: movedUp,
    [styles.treeOnTop]: treeOnTop,
    [styles.alignLeft]: alignLeft,
  })

  return (
    <div className={doubleBorderBoxClasses}>
      <div>{children}</div>
    </div>
  )
}

export default DoubleBorderBox
