import React from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"

import * as styles from "./ListOptionsBox.module.sass"

const ListOptionsBox = props => {
  // props
  const {
    filters = {},
    allFilters = "general.all",
    handleFilterChange = () => { },
    handleSorterChange = () => { },
    isHiddenMobile,
    sorters = {},
  } = props

  const asideClasses = classNames({
    menu: true,
    "is-hidden-mobile": isHiddenMobile === true,
    [styles.menu]: true,
  })

  let sortedFilters =
    Object.values(filters)
      .filter(f => f.id !== allFilters)
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })

  const allFilterItem = Object.values(filters).find(f => f.id === allFilters)
  if (allFilterItem) {
    sortedFilters.unshift(Object.values(filters).find(f => f.id === allFilters))
  }

  const sortedSorters = Object.values(sorters).sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })

  return (
    <aside className={asideClasses}>
      {sortedFilters.length > 0 && (
        <React.Fragment>
          <p className="menu-label">
            <FormattedMessage id="general.filtering" />
          </p>
          <ul className="menu-list">
            {sortedFilters.map(filter => (
              <li key={filter.id}>
                <div className="field py-1">
                  <input
                    className="is-checkradio has-no-border"
                    id={filter.id}
                    type="checkbox"
                    name={filter.id}
                    checked={filter.enabled}
                    onChange={handleFilterChange}
                  />
                  <label htmlFor={filter.id}>
                    <span className={filter.enabled ? "has-text-lime" : ""}>
                      <React.Fragment>
                        {filter.label}{" "}
                        <small className="has-text-grey-light">
                          ({filter.count})
                        </small>
                      </React.Fragment>
                    </span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
      {sortedSorters.length > 0 && (
        <React.Fragment>
          <p className="menu-label pt-4">
            <FormattedMessage id="general.sorting" />
          </p>
          <ul className="menu-list">
            {sortedSorters.map(sorter => (
              <li key={sorter.id}>
                <div className="field py-1">
                  <a
                    className={styles.asideLink}
                    onClick={() => handleSorterChange(sorter.id)}
                  >
                    <span>{sorter.order === "asc" ? "↑ " : "↓ "}</span>
                    {sorter.label}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </aside>
  )
}

export default ListOptionsBox
