import React from "react"
import { useIntl } from "react-intl"

import DoubleBorderBox from "./DoubleBorderBox"

const CollectionEmptyBox = () => {
  // hooks
  const { formatMessage } = useIntl()

  return (
    <div className="column is-relative">
      <DoubleBorderBox treeOnTop={true}>
        {formatMessage({ id: "collection.is-empty" })}
      </DoubleBorderBox>
    </div>
  )
}

export default CollectionEmptyBox
