import React from "react"

const Modal = props => {
  // props
  const { isModalOpen, handleModalToggleClick, children } = props

  return (
    <div className={`modal${isModalOpen ? " is-active" : ""}`}>
      <div className="modal-background" onClick={handleModalToggleClick}></div>
      <div className="modal-content">{children}</div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={handleModalToggleClick}
      ></button>
    </div>
  )
}

export default Modal
