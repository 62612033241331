import React, { useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { graphql } from "gatsby"
import { IoFunnelOutline } from "react-icons/io5"
import Sticky from "react-stickynode"
import { useQueryParam, ArrayParam, StringParam } from "use-query-params"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import Layout from "../layouts"
import { locales, stickyTop } from "../config/main"

import CollectionEmptyBox from "../components/CollectionEmptyBox"
import ListOptionsBox from "../components/ListOptionsBox"
import Modal from "../components/Modal"
import ProductBox from "../components/ProductBox"

import messages from "../locales/translations.json"
import { getShopifyContentfulMatcher } from "../utils/product-matcher"
import useWindowWidth from "../utils/window-width"

const CollectionPage = props => {
  // props
  const {
    data: {
      shopifyCollection: { title, products },
      allContentfulCollection: { nodes: contentfulCollections },
      allContentfulProduct: { nodes: contentfulProducts },
    },
    pageContext: { locale },
  } = props

  // hooks
  const { formatMessage } = useIntl()
  const [modalContent, setModalContent] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleModalToggleClick = children => {
    setIsModalOpen(!isModalOpen)
    if (modalContent !== children) {
      setModalContent(children)
    }
  }

  // query params:
  //   - f {Array} filters
  //   - s {String} sort order ("asc" or "desc", defaults to "asc")
  const [productTypes, setProductTypes] = useQueryParam("f", ArrayParam)
  const [sortOrder, setSortOrder] = useQueryParam("s", StringParam)

  // data
  const allFilters = "general.all"

  // keep a list of product type mappings from any locale to DE to support localized filters
  const productTypeMappings = Object.keys(messages.de)
    .filter(id => id.indexOf("product.type.") === 0)
    .reduce((map, cur) => {
      let updatedMap = {
        ...map,
      }
      locales.forEach(locale => {
        updatedMap[messages[locale][cur]] = messages[locale][cur]
      })
      return updatedMap
    }, {})

  /**
   * Map product type to corresponding translate key
   * @param {String} filter
   * @returns {String}
   */
  const mapProductTypeToTranslateKey = filter => {
    const knownProductTypes = Object.keys(messages.de)
      .filter(id => id.indexOf("product.type.") === 0)
      .reduce((prev, id) => {
        return {
          ...prev,
          [messages.de[id]]: id,
        }
      }, {})
    return knownProductTypes[filter] || filter
  }

  /**
   * Determines if a product’s product type is currently enabled
   * @param {Product} product
   * @returns {Boolean}
   */
  const isProductTypeEnabled = product => {
    let translatedProductTypes = []
    if (typeof productTypes !== "undefined") {
      translatedProductTypes = productTypes.map(
        productType => productTypeMappings[productType] || productType
      )
    }

    return (
      typeof productTypes !== "undefined" &&
      translatedProductTypes.includes(product.productType)
    )
  }

  /**
   * get localized collection title
   * @returns
   */
  const getLocalizedCollectionTitle = () => {
    return (
      contentfulCollections.find(c => c.node_locale === locale)?.collectionTitle || title
    )
  }

  /**
   * get localized collection value if it exist, otherwise `false`
   * @param {String} key
   * @returns
   */
  const getLocalizedCollectionValue = key => {
    const match = contentfulCollections.find(c => c.node_locale === locale)
    return match && match[key] ? match[key] : false
  }

  /**
   * get product meta data if available
   * @param {Object} product
   * @param {String} locale
   * @returns
   */
  const getProductMetaData = (product, locale) => {
    return contentfulProducts.find(c => {
      return (
        product.variants.some(
          v => getShopifyContentfulMatcher(v, c)
        ) && c.node_locale === locale
      )
    })
  }

  // extract product types from products, count occurrences, and
  // set current state based on query parameter
  let filters = products.reduce((map, product) => {
    if (map[product.productType]) {
      map[product.productType].count++
    } else {
      map[product.productType] = {
        count: 1,
        enabled: isProductTypeEnabled(product),
        id: product.productType,
        label: formatMessage({
          id: mapProductTypeToTranslateKey(product.productType),
        }),
        translateKey: mapProductTypeToTranslateKey(product.productType),
      }
    }
    return map
  }, {})

  // add `allFilters`, set overall count, and set current state
  // based on emptiness of query parameter
  filters[allFilters] = {
    count: products.length,
    enabled: typeof productTypes === "undefined",
    id: allFilters,
    label: formatMessage({ id: allFilters }),
    translateKey: allFilters,
  }

  // filtering
  let filteredProducts = [...products].filter(product => {
    return typeof productTypes === "undefined" || isProductTypeEnabled(product)
  })

  // initialize sorters and set sort order based on query parameter,
  // as well as an `accessor` for sorting
  const SORT_ORDER_ASCENDING = "asc"
  const SORT_ORDER_DESCENDING = "desc"

  let sorters = {
    price: {
      accessor: product =>
        parseFloat(product.priceRangeV2.minVariantPrice.amount),
      id: "price",
      label: formatMessage({ id: "general.price" }),
      order: sortOrder || SORT_ORDER_ASCENDING,
    },
  }

  // sorting
  let filteredAndSortedProducts = [
    ...filteredProducts.sort((a, b) => {
      let aPrice = parseFloat(a.priceRangeV2.minVariantPrice.amount)
      let bPrice = parseFloat(b.priceRangeV2.minVariantPrice.amount)
      if (aPrice < bPrice)
        return sorters.price.order === SORT_ORDER_ASCENDING ? -1 : 1
      if (aPrice > bPrice)
        return sorters.price.order === SORT_ORDER_ASCENDING ? 1 : -1
      return 0
    }),
  ]

  /**
   * handle filter change
   * @param {Event} e
   */
  const handleFilterChange = e => {
    if (e.target.name === allFilters) {
      // reset query param
      setProductTypes(undefined)

      // scroll into view
      scrollToTop()
    } else {
      // count currently enabled filters
      const getEnabledFilters = Object.values(filters).filter(f => f.enabled)

      // avoid de-selecting last remaining filter
      if (
        getEnabledFilters.length > 1 ||
        getEnabledFilters[0] !== e.target.name
      ) {
        // toggle filter’s `enabled`
        filters[allFilters].enabled = false
        filters[e.target.name].enabled = !filters[e.target.name].enabled

        // update query param
        setProductTypes([
          ...Object.values(filters)
            .filter(filter => filter.enabled)
            .map(filter => formatMessage({ id: filter.translateKey })),
        ])

        // scroll into view
        scrollToTop()
      }
    }
  }

  const width = useWindowWidth()
  let stickyEnabled = false
  if (width <= 1216) {
    stickyEnabled = true
  }
  if (width <= 1024) {
    stickyEnabled = true
  }
  if (width <= 768) {
    stickyEnabled = false
  }
  if (width <= 480) {
    stickyEnabled = false
  }

  /**
   * handle sorter change
   * @param {String} sorter
   */
  const handleSorterChange = sorter => {
    // invert sort order
    sorters[sorter].order =
      sorters[sorter].order === SORT_ORDER_ASCENDING
        ? SORT_ORDER_DESCENDING
        : SORT_ORDER_ASCENDING
    setSortOrder(sorters[sorter].order)
  }

  // reference to the product grid element
  const productGridRef = useRef(null)

  /**
   * scroll product grid into view
   */
  const scrollToTop = () => {
    productGridRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  // get localized values
  const localizedSeoDescription = getLocalizedCollectionValue("seoDescription")
  const seoDescriptionText = localizedSeoDescription
    ? documentToPlainTextString(JSON.parse(localizedSeoDescription.raw))
    : getLocalizedCollectionTitle()

  return (
    <Layout
      title={
        getLocalizedCollectionValue("seoTitle") || getLocalizedCollectionTitle()
      }
      description={seoDescriptionText}
      metatags={getLocalizedCollectionValue("seoMetatags")}
      isModalOpen={isModalOpen}
    >
      <section className="section">
        <div className="container">
          <h1 className="title">{getLocalizedCollectionTitle()}</h1>
        </div>
      </section>

      <Modal
        isModalOpen={isModalOpen}
        handleModalToggleClick={handleModalToggleClick}
      >
        <div className="box">
          <ListOptionsBox
            filters={filters}
            allFilters={allFilters}
            handleFilterChange={handleFilterChange}
            handleSorterChange={handleSorterChange}
            isHiddenMobile={false}
            sorters={sorters}
          />
          <p className="pt-4">
            <button className="button" onClick={handleModalToggleClick}>
              <FormattedMessage id="general.close" />
            </button>
          </p>
        </div>
      </Modal>

      <section className="section" id="content">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter-desktop is-one-third-tablet is-12-mobile">
              <button
                className="button is-hidden-tablet filtering-and-sorting"
                onClick={handleModalToggleClick}
              >
                <span className="icon is-small">
                  <IoFunnelOutline />
                </span>
                <span>
                  <FormattedMessage id={"general.filtering-and-sorting"} />
                </span>
              </button>

              <Sticky top={stickyTop} bottomBoundary="#content" enabled={stickyEnabled}>
                <ListOptionsBox
                  filters={filters}
                  allFilters={allFilters}
                  handleFilterChange={handleFilterChange}
                  handleSorterChange={handleSorterChange}
                  isHiddenMobile={true}
                  sorters={sorters}
                />
              </Sticky>
            </div>
            <div
              className="column is-three-quarters-desktop is-two-thirds-tablet is-12-mobile"
              ref={productGridRef}
            >
              <div className="columns is-multiline is-mobile is-equal-height">
                {filteredAndSortedProducts.map(product => (
                  <div
                    className="column px-0 is-one-third-widescreen is-half-desktop is-half-touch is-12-phone"
                    key={product.id}
                  >
                    <ProductBox
                      area={product.id}
                      product={{
                        ...product,
                        handle:
                          getProductMetaData(product, locale)?.productSlug ||
                          product.handle,
                        title:
                          getProductMetaData(product, locale)?.productTitle ||
                          product.title,
                      }}
                      locale={locale}
                    />
                  </div>
                ))}
                {filteredAndSortedProducts.length === 0 && (
                  <CollectionEmptyBox />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <pre>{JSON.stringify(contentfulCollections, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(filteredAndSortedProducts, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(contentfulProducts, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(productVariantIds, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(productVariantIdsBase64, null, 2)}</pre> */}
    </Layout>
  )
}

export default CollectionPage

export const CollectionPageQuery = graphql`
  query ($collectionId: String!, $productVariantIdsBase64: [String!]!) {
    shopifyCollection(storefrontId: { eq: $collectionId }) {
      description
      descriptionHtml
      id
      title
      products {
        description
        descriptionHtml
        handle
        hasOnlyDefaultVariant
        hasOutOfStockVariants
        id
        images {
          altText
          id
          gatsbyImageData(
            layout: CONSTRAINED
            width: 400
            aspectRatio: 0.75
            breakpoints: [200, 300, 400]
          )
        }
        isGiftCard
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        productType
        shopifyId
        status
        storefrontId
        tags
        title
        totalVariants
        variants {
          availableForSale
          compareAtPrice
          id
          price
          shopifyId
          storefrontId
          title
        }
      }
    }
    allContentfulCollection(
      filter: { shopifyCollectionStorefrontId: { eq: $collectionId } }
    ) {
      nodes {
        node_locale
        collectionTitle
        seoTitle
        seoDescription {
          raw
        }
        seoMetatags
      }
    }
    allContentfulProduct(
      filter: { shopifyProductVariantId: { in: $productVariantIdsBase64 } }
    ) {
      nodes {
        node_locale
        productSlug
        productTitle
        shopifyProductVariantId
      }
    }
  }
`
